body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card-heroSection5{
  width: 18em;
  height: 17em;  
}

.card-img-top{
  width: 17em;
  height: 10em;
  
}

.card-title{
  text-align: center;
}

.card-text{
  text-align: justify;
}

.text-muted{
  font-size: 0.8em; 
}

